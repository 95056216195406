<template>
<div id="app" class="site container hfeed" style="height: auto !important">
  <nav id="site-navigation" class="has-branding main-navigation grid-container nav-align-right sub-menu-right" aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
    <div class="inside-navigation grid-container">
      <div class="navigation-branding">
        <div class="site-logo">
          <a href="https://whatsplusapk.com/" title="GBWhatsApp" rel="home">
            <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/logo.webp" title="GBWhatsApp">
          </a>
        </div>
        <p class="main-title" itemprop="headline">
          <a href="https://whatsplusapk.com/" rel="home">
            GB WhatsApp App
          </a>
        </p>
      </div>

      <div id="primary-menu" class="main-nav">
        <ul id="menu-primary-menu" class="menu sf-menu">
          <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
            <a href="https://whatsplusapk.com/" aria-current="page">GB WhatsApp</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://whatsplusapk.com/gbwhatsapp-apk-download/">Download</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://whatsplusapk.com/disclaimer/">Disclaimer</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div id="content" class="site-content grid-container" style="height: auto !important">
    <article id="article" class="center" style="height: auto !important">
      <section id="downloadpage" style="height: auto !important">
        <div class="download-banner">
          <div class="hr">
            <div class="left f12">
              <div v-if="!$global.isMobileDevice()" class="wp-block-image banner-img ic sdw">
                <img decoding="async" src="../assets/logo.webp" alt="GBWhatsApp" class="wp-image-615">
              </div>
            </div>

            <div class="right f1">
              <div class="mobile">
                <div v-if="$global.isMobileDevice()" class="wp-block-image banner-img ic sdw">
                  <img decoding="async" src="../assets/logo.webp" alt="GBWhatsApp" class="wp-image-615">
                </div>
                <h1 class="section-1-rule">
                  GBWhatsApp Apk Download with New Updated Features
                </h1>
              </div>

              <p>
                Our APK is safe and spam-free—download with confidence!
              </p>
              <p>
                By <a href="https://whatsplusapk.com/" class="jump-url">whatsplusapk.com</a>
              </p>
            </div>
          </div>
          <div class="is-horizontal is-layout-flex wp-container-1 wp-block-buttons">
            <div class="wp-block-button">
              <a class="wp-block-button__link wp-element-button" @click="downloadfrom('bigbtn')" style="border-radius: 2px;">GBWhatsApp APK Download
              </a>
            </div>
          </div>
        </div>

        <div class="info">
          <h2>GBWhatsApp Apk Specification</h2>
          <table>
            <tr>
              <td>Name</td>
              <td>GB WhatsApp</td>
            </tr>
            <tr>
              <td>Last Updated</td>
              <td>1 Day Ago</td>
            </tr>
            <tr>
              <td>License</td>
              <td>Free</td>
            </tr>
            <tr>
              <td>Supported System</td>
              <td>Android 5.0+</td>
            </tr>
            <tr>
              <td>Category</td>
              <td>Instant Messaging</td>
            </tr>
            <tr>
              <td>Rate</td>
              <td>⭐⭐⭐⭐</td>
            </tr>
          </table>
        </div>

        <p>
          Don't wait any longer! Click the download button now to get GB WhatsApp directly to your device. It's quick, secure, and hassle-free. Start enjoying all the benefits in minutes—hit <strong>GBWhatsApp APK Download</strong> and you're good to go!
        </p>

        <div class="info">
          <h2>GB WhatsApp Download App Old Version</h2>
          <div class="old-version-list">
            <div class="old-version-item">
              <h4>GB WhatsApp v18.10</h4>
              <div class="is-horizontal is-layout-flex wp-container-1 wp-block-buttons">
                <div class="wp-block-button">
                  <a class="wp-block-button__link wp-element-button big-download-btn" @click="downloadfrom('bigbtn')" style="border-radius: 2px;">Download
                  </a>
                </div>
              </div>
            </div>
            <div class="old-version-item">
              <h4>GB WhatsApp v18.00</h4>
              <div class="is-horizontal is-layout-flex wp-container-1 wp-block-buttons">
                <div class="wp-block-button">
                  <a class="wp-block-button__link wp-element-button big-download-btn" @click="downloadfrom('bigbtn')" style="border-radius: 2px;">Download
                  </a>
                </div>
              </div>
            </div>
            <div class="old-version-item">
              <h4>GB WhatsApp v17.85</h4>
              <div class="is-horizontal is-layout-flex wp-container-1 wp-block-buttons">
                <div class="wp-block-button">
                  <a class="wp-block-button__link wp-element-button big-download-btn" @click="downloadfrom('bigbtn')" style="border-radius: 2px;">Download
                  </a>
                </div>
              </div>
            </div>
            <div class="old-version-item">
              <h4>GB WhatsApp v17.80</h4>
              <div class="is-horizontal is-layout-flex wp-container-1 wp-block-buttons">
                <div class="wp-block-button">
                  <a class="wp-block-button__link wp-element-button big-download-btn" @click="downloadfrom('bigbtn')" style="border-radius: 2px;">Download
                  </a>
                </div>
              </div>
            </div>
            <div class="old-version-item">
              <h4>GB WhatsApp 17.76</h4>
              <div class="is-horizontal is-layout-flex wp-container-1 wp-block-buttons">
                <div class="wp-block-button">
                  <a class="wp-block-button__link wp-element-button big-download-btn" @click="downloadfrom('bigbtn')" style="border-radius: 2px;">Download
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
      <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
        <footer class="site-info">
          <div class="inside-site-info grid-container">
            <div class="footer-bar">
              <a href="https://whatsplusapk.com/blogs/">Blog</a>
              |
              <a href="https://whatsplusapk.com/disclaimer/">Disclaimer</a>
              |
              <a href="https://whatsplusapk.com/about-us/">About Us</a>
            </div>
            <div class="copyright-bar">
              2025 © All Rights Reserved <strong><a href="https://whatsplusapk.com/">GBWhatsApp</a></strong>
            </div>
          </div>
        </footer>
      </div>
    </article>
  </div>
</div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/download.scss';

export default {
  name: 'Download',
  data() {
    return {
      pageName: 'download',
      showapk: false,

      apk: null,
      from: 'gb',
      filename: 'gb',
    };
  },
  mounted() {
    this.from = this.$global.GetQueryString('from') || this.from;
    this.filename = this.$global.GetQueryString('filename') || this.filename;
    console.log(this.$global.GetQueryString('from'));
    if (this.$global.GetQueryString('from')) {
      this.getAPK();
    } else {
      this.getOfficalApk();
    }

    // window.adsTag.renderReward(54983, (rewardedStatus) => {
    // 	if (rewardedStatus) {
    // 		// 执行激励广告展示完成后操作
    // 		console.log("广告已经展示");
    // 	} else {
    // 		// 执行激励广告展示未完成操作
    // 	}
    // });

    // window.adsTag.adBreak({
    //   zoneId: 54983,
    //   type: 'reward',
    //   rewardSkipSecond: 15,
    //   adBreakDone: (viewed) => {
    //     console.log('aaa');
    //     if (viewed) {
    //       // 广告成功展示
    //       console.log('1');
    //     } else {
    //       // 广告展示失败，或由用户中止
    //       console.log('2');
    //     }
    //   },
    // });
    // this.apk = process.env.VUE_APP_GBLINK
  },
  methods: {
    downloadfrom(reason) {
      this.download();
      this.$logEvent(`download_from_${reason}`, 'download');
      // window.location.href = `https://gbapp.pro?apps=gb&domain=gbwhatsapp.chat&appName=GB_gbwhatsapp.chat`;
    },
    gotoblog() {
      window.location.href = '/blogs';
    },
    getAPK() {
      this.$server
        .getAPPInfo({
          promotionChannel: this.from,
          promotionName: this.filename,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.apk = res.data.apk;
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    getOfficalApk(type = 1, domain = null, appname = null) {
      this.$server
        .getOfficalApk({
          domain: domain || 'gbwhatsapp.chat',
          appName: appname || 'GB_gbwhatsapp.chat',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    download() {
      console.log(this.apk);
      // this.showapk = true
      if (this.apk) {
        this.$global.download(this.apk);
      }
    },
    // download() {
    // 	console.log(this.apk);
    // 	this.$global.download(this.apk);
    // },
  },
};
</script>

<style lang="scss" scoped>
.download-banner {
  // background-image: radial-gradient(circle farthest-corner at 10% 20%, rgba(176, 229, 208, 0.7) 42%, rgba(92, 202, 238, 0.35) 93.6%);
  padding: 60px 0px 10px;
  border-radius: 0 0 25px 25px;
  margin-bottom: 30px;

  .hr {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .left {
      text-align: center;
      flex: 1;

      .banner-img {
        margin: 0;
        width: 110px;

        img {
          border-radius: 25px;
          height: auto;
        }
      }
    }

    .right {
      .mobile {
        display: flex;
        gap: 8px;
        margin-bottom: 12px;
        .banner-img {
          width: 100px;
          margin: 0;
        }
      }
      .section-1-rule {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 28px;
      }

      p {
        margin-bottom: 5px;
        font-size: 16px;
      }

    }
  }

  .wp-block-buttons {
    margin-top: 15px;
  }
}

.info {
  margin-bottom: 30px;

  h2 {
    font-size: 22px;
    font-weight: 700;
    padding-bottom: 5px;
    border-bottom: 3px solid #282828;
    margin-bottom: 15px;
  }

  table {
    width: 100%;
    font-size: 17px;
    line-height: 25px;
    border: none;


    tr {
      td {
        padding: 12px 10px;
        border: none;
        color: #282828;
        font-size: 16px;
      }
    }

    tr:nth-child(even) {
      td {
        background: #e5f2ff;
      }
    }

    tr:first-child {
      td{
        font-weight: 600;
        background: #ffffaf;
      }
    }
  }
}

@media (max-width: 768px) {
  .download-banner {
    padding: 40px 20x 0;

    .hr {
      // flex-direction: column;
      gap: 0;
      align-items: center;
      justify-content: center;

      .left {
        .banner-img {
          width: 30px;
          border-radius: 0;

          img {
            border-radius: 0;
          }
        }
      }

      .right {
        margin-top: 8px;

        .section-1-rule {
          font-size: 22px;
          margin: 0;
        }
        p {
          margin-bottom: 3px;
        }
      }
    }
  }

  table {
    width: 80%;
  }
}
</style>
